<template>
  <div class="common-wrapper">
    <div class="top block">
      <div class="first item">
        <section class="title">
          <a-dropdown :trigger="['click']" class="dropdownStyle" v-model:visible="visible">
            <div>
              <span>{{ projectInfo.name }}</span>
              <DownOutlined />
            </div>
            <template #overlay>
              <div class="treeStyle">
                <a-tree v-model:checkedKeys="projectIds" :tree-data="treeData"
                  :fieldNames="{ children: 'children', title: 'name', key: 'projectBizId' }" @check="handleSelectTree"
                  checkable>
                </a-tree>
              </div>
            </template>
          </a-dropdown>
        </section>
        <div>
          <section>
            <span>项目面积</span>
            <p>{{ projectData.area }}<em>&nbsp;&nbsp;m²</em></p>
          </section>
          <section>
            <span>客房数量</span>
            <p>{{ projectData.num }}</p>
          </section>
        </div>
      </div>
      <div class="item normal">
        <div>
          <svg-icon name="electric" class="icon-tip"></svg-icon>
          <span>当月用电</span>
          <section>
            <p>{{ energyData.electricityMonth }}<em>&nbsp;&nbsp;kWh</em></p>
            <template v-if="energyData.electricityQOQ !== null">
              <strong>环比</strong>
              &nbsp;&nbsp;
              <template v-if="energyData.electricityQOQ >= 0">
                <svg-icon name="caret-up" size="12"></svg-icon>
                <strong style="color: #52C41A">{{ (energyData.electricityQOQ * 100).toFixed(1)
                }}%</strong>
              </template>
              <template v-else>
                <svg-icon name="caret-down" size="12"></svg-icon>
                <strong style="color: #F5222D">{{ (energyData.electricityQOQ * (-100)).toFixed(1) }}%</strong>
              </template>
            </template>
            <template v-else>
              <div>
                <strong>环比</strong>
                &nbsp;&nbsp;
                <strong>--</strong>
              </div>
            </template>
          </section>
        </div>
        <div>
          <span>当年用电</span>
          <section>
            <p>{{ energyData.electricityYear }}<em>&nbsp;&nbsp;kWh</em></p>
            <template v-if="energyData.electricityYOY !== null">
              <strong>同比</strong>
              &nbsp;&nbsp;
              <template v-if="energyData.electricityYOY > 0">
                <svg-icon name="caret-up" size="12"></svg-icon>
                <strong style="color: #52C41A">{{ (energyData.electricityYOY * 100).toFixed(1)
                }}%</strong>
              </template>
              <template v-else>
                <svg-icon name="caret-down" size="12"></svg-icon>
                <strong style="color: #F5222D">{{ (energyData.electricityYOY * (-100)).toFixed(1) }}%</strong>
              </template>
            </template>
            <template v-else>
              <div>
                <strong>同比</strong>
                &nbsp;&nbsp;
                <strong>--</strong>
              </div>
            </template>

          </section>
        </div>
      </div>
      <div class="item normal">
        <div>
          <svg-icon name="gas" class="icon-tip"></svg-icon>
          <span>当月用气</span>
          <section>
            <p>{{ energyData.gasMonth }}<em>&nbsp;&nbsp;m³</em></p>
            <template v-if="energyData.gasQOQ !== null">
              <strong>环比</strong>
              &nbsp;&nbsp;
              <template v-if="energyData.gasQOQ >= 0">
                <svg-icon name="caret-up" size="12"></svg-icon>
                <strong style="color: #52C41A">{{ (energyData.gasQOQ * 100).toFixed(1)
                }}%</strong>
              </template>
              <template v-else>
                <svg-icon name="caret-down" size="12"></svg-icon>
                <strong style="color: #F5222D">{{ (energyData.gasQOQ * (-100)).toFixed(1) }}%</strong>
              </template>
            </template>
            <template v-else>
              <div>
                <strong>环比</strong>
                &nbsp;&nbsp;
                <strong>--</strong>
              </div>
            </template>
          </section>
        </div>
        <div>
          <span>当年用气</span>
          <section>
            <p>{{ energyData.gasYear }}<em>&nbsp;&nbsp;m³</em></p>
            <template v-if="energyData.gasYOY !== null">
              <strong>同比</strong>
              &nbsp;&nbsp;
              <template v-if="energyData.gasYOY >= 0">
                <svg-icon name="caret-up" size="12"></svg-icon>
                <strong style="color: #52C41A">{{ (energyData.gasYOY * 100).toFixed(1)
                }}%</strong>
              </template>
              <template v-else>
                <svg-icon name="caret-down" size="12"></svg-icon>
                <strong style="color: #F5222D">{{ (energyData.gasYOY * (-100)).toFixed(1) }}%</strong>
              </template>
            </template>
            <template v-else>
              <div>
                <strong>同比</strong>
                &nbsp;&nbsp;
                <strong>--</strong>
              </div>
            </template>

          </section>
        </div>
      </div>
      <div class="item normal">
        <div>
          <svg-icon name="water" class="icon-tip"></svg-icon>
          <span>当月用水</span>
          <section>
            <p>{{ energyData.waterMonth }}<em>&nbsp;&nbsp;m³</em></p>
            <template v-if="energyData.waterQOQ !== null">
              <strong>环比</strong>
              &nbsp;&nbsp;
              <template v-if="energyData.waterQOQ >= 0">
                <svg-icon name="caret-up" size="12"></svg-icon>
                <strong style="color: #52C41A">{{ (energyData.waterQOQ * 100).toFixed(1)
                }}%</strong>
              </template>
              <template v-else>
                <svg-icon name="caret-down" size="12"></svg-icon>
                <strong style="color: #F5222D">{{ (energyData.waterQOQ * (-100)).toFixed(1) }}%</strong>
              </template>
            </template>
            <template v-else>
              <div>
                <strong>环比</strong>
                &nbsp;&nbsp;
                <strong>--</strong>
              </div>
            </template>
          </section>
        </div>
        <div>
          <span>当年用水</span>
          <section>
            <p>{{ energyData.waterYear }}<em>&nbsp;&nbsp;m³</em></p>
            <template v-if="energyData.waterYOY !== null">
              <strong>同比</strong>
              &nbsp;&nbsp;
              <template v-if="energyData.waterYOY >= 0">
                <svg-icon name="caret-up" size="12"></svg-icon>
                <strong style="color: #52C41A">{{ (energyData.waterYOY * 100).toFixed(1)
                }}%</strong>
              </template>
              <template v-else>
                <svg-icon name="caret-down" size="12"></svg-icon>
                <strong style="color: #F5222D">{{ (energyData.waterYOY * (-100)).toFixed(1) }}%</strong>
              </template>
            </template>
            <template v-else>
              <div>
                <strong>同比</strong>
                &nbsp;&nbsp;
                <strong>--</strong>
              </div>
            </template>
          </section>
        </div>
      </div>
      <div class="item normal">
        <div>
          <svg-icon name="co2" class="icon-tip"></svg-icon>
          <span>当月碳排</span>
          <section>
            <p>{{ energyData.carbonMonth }}<em>&nbsp;&nbsp;t</em></p>
            <template v-if="energyData.carbonQOQ !== null">
              <strong>环比</strong>
              &nbsp;&nbsp;
              <template v-if="energyData.carbonQOQ >= 0">
                <svg-icon name="caret-up" size="12"></svg-icon>
                <strong style="color: #52C41A">{{ (energyData.carbonQOQ * 100).toFixed(1)
                }}%</strong>
              </template>
              <template v-else>
                <svg-icon name="caret-down" size="12"></svg-icon>
                <strong style="color: #F5222D">{{ (energyData.carbonQOQ * (-100)).toFixed(1) }}%</strong>
              </template>
            </template>
            <template v-else>
              <div>
                <strong>环比</strong>
                &nbsp;&nbsp;
                <strong>--</strong>
              </div>
            </template>
          </section>
        </div>
        <div>
          <span>当年碳排</span>
          <section>
            <p>{{ energyData.carbonYear }}<em>&nbsp;&nbsp;t</em></p>
            <template v-if="energyData.carbonYOY !== null">
              <strong>同比</strong>
              &nbsp;&nbsp;
              <template v-if="energyData.carbonYOY >= 0">
                <svg-icon name="caret-up" size="12"></svg-icon>
                <strong style="color: #52C41A">{{ (energyData.carbonYOY * 100).toFixed(1)
                }}%</strong>
              </template>
              <template v-else>
                <svg-icon name="caret-down" size="12"></svg-icon>
                <strong style="color: #F5222D">{{ (energyData.carbonYOY * (-100)).toFixed(1) }}%</strong>
              </template>
            </template>
            <template v-else>
              <div>
                <strong>同比</strong>
                &nbsp;&nbsp;
                <strong>--</strong>
              </div>
            </template>
          </section>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="sevenDay">
        <div class="title_action">
          <p>日用电曲线</p>
        </div>
        <div style="width: 100%;height: 300px;" ref="dayEnergyRef"></div>
      </div>
      <div class="weather">
        <p class="ttitle">当前环境与天气信息 <span> <svg-icon name="address" size="12"></svg-icon>{{ weatherInfo.cityName }}</span>
        </p>
        <div>
          <div class="left">
            <img :src="weatherInfo.blueUrl" alt="">
            <div>
              <div>{{ weatherInfo.temp }}<em>℃</em></div>
              <strong>{{ weatherInfo.weatherStatus }}</strong>
            </div>
          </div>
          <div class="right">
            <div>
              <section>
                <svg-icon name="pm25"></svg-icon>
                <span>PM2.5</span>
              </section>
              <section class="pm25Grade">
                <span :style="aqiGradeStyle">{{ weatherInfo.aqiGrade }}</span>
                {{ weatherInfo.pm25 }}ug/m³
              </section>
            </div>
            <div>
              <section>
                <svg-icon name="temp"></svg-icon>
                <span>温度</span>
              </section>
              <section>
                {{ weatherInfo.minTemp }}℃~{{ weatherInfo.maxTemp }}℃
              </section>
            </div>
            <div>
              <section>
                <svg-icon name="shidu"></svg-icon>
                <span>湿度</span>
              </section>
              <section>
                {{ weatherInfo.humidity }}%
              </section>
            </div>
            <div>
              <section>
                <svg-icon name="wind"></svg-icon>
                <span>风速</span>
              </section>
              <section>
                {{ weatherInfo.windDirection }}，{{ weatherInfo.windLevel }}
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="sevenDay">
        <div class="title_action">
          <p>周能耗数据</p>
          <a-radio-group v-model:value="sevenDayCurrent" button-style="solid" size="small" @change="weekenergyChange">
            <a-radio-button value="电">用电</a-radio-button>
            <a-radio-button value="水">用水</a-radio-button>
            <a-radio-button value="气">用气</a-radio-button>
          </a-radio-group>
        </div>
        <div style="width: 100%;height: 300px;" ref="sevenDayEnergyRef"></div>
      </div>
      <div class="yearAnalysis" style="margin-right: 0;">
        <p class="ttitle">当年能耗成本</p>
        <div style="display: flex;justify-content: space-between;align-items:center;width:100%;height: 300px;">
          <div class="yearCost" style="width: 30%;">
            <div style="white-space: nowrap;">
              <svg-icon name="yuan" size="18" style="margin-right: 4px;vertical-align: middle;"></svg-icon>
              <span style="vertical-align: middle">当年能耗总成本</span>
            </div>
            <div style="font-size: 24px;line-height: 24px;font-weight: 500;">
              <span>{{ yearCost }}</span>
              <span class="unit" style="margin-left: 4px;font-size: 12px;font-weight: 400;">元</span>
            </div>
            <div>
              <template v-if="energyData.yearCostYoy !== null">
                <strong>同比</strong>
                &nbsp;&nbsp;
                <template v-if="energyData.yearCostYoy > 0">
                  <svg-icon name="caret-up" size="12"></svg-icon>
                  <strong style="color: #52C41A">{{ energyData.yearCostYoy * 100
                  }}%</strong>
                </template>
                <template v-else>
                  <svg-icon name="caret-down" size="12"></svg-icon>
                  <strong style="color: #F5222D">{{ energyData.yearCostYoy ? (energyData.yearCostYoy * (-100)).toFixed(1) :
                    0 }}%</strong>
                </template>
              </template>
              <template v-else>
                <div>
                  <strong>同比</strong>
                  &nbsp;&nbsp;
                  <strong>--</strong>
                </div>
              </template>
            </div>
          </div>
          <div style="width: 10%;min-width: 40px;"></div>
          <div style="flex: 1;height: 300px;" ref="yearEnergyChartRef"></div>
        </div>
        <!-- <div class="yearAnalysisLegend">
          <section>
            <span>电费<em :style='{ background: "#165DFF" }'></em></span>
            <span class="mar">峰<em :style='{ background: "#147AD6" }'></em></span>
            <span class="mar">谷<em :style="{ background: '#EC6666' }"></em></span>
            <span class="mar">平<em :style="{ background: '#79D2DE' }"></em></span>
            <span>水费<em :style="{ background: '#00B2FF' }"></em></span>
            <span class="mar">购水<em :style="{ background: '#945FB9' }"></em></span>
            <span class="mar">污水<em :style="{ background: '#1E9493' }"></em></span>
            <span>燃气<em :style="{ background: '#FF9845' }"></em></span>
          </section>
        </div> -->
      </div>
      <!-- <div class="yearData">
        <p class="ttitle">当年碳排数据</p>
        <div class="other_info">
          <div>
            <section>
              <span>标准煤</span>
              <p>{{ energyCarbonInfo.totalCoal }}<em>t</em></p>
            </section>
            <section>
              <span>二氧化碳</span>
              <p>{{ energyCarbonInfo.totalCO2 }}<em>t</em></p>
            </section>
          </div>
          <div>
            <section>
              <span>二氧化硫</span>
              <p>{{ energyCarbonInfo.totalSO2 }}<em>t</em></p>
            </section>
            <section>
              <span>粉尘</span>
              <p>{{ energyCarbonInfo.totalDust }}<em>t</em></p>
            </section>
          </div>
        </div>
        <div style="width: 90%;height: 300px;" ref="yearTanChartRef"></div>
      </div> -->
    </div>
    <div class="block yearElectricity">
      <div class="title_action">
        <p>当年电耗分析</p>
        <a-radio-group v-model:value="boardType" button-style="solid" size="small" @change="initElectricity">
          <a-radio-button value="1">分类</a-radio-button>
          <a-radio-button value="2">分时</a-radio-button>
          <a-radio-button value="3">分区</a-radio-button>
        </a-radio-group>
      </div>
      <div style="width: 30%;height: 300px;" ref="yearElectricityChartRef"></div>
      <div style="width: 70%;height: 370px;" ref="yearElectricityChartRef2"></div>
      <div style="position: absolute;bottom: 33px;left: 0; width: 30%;text-align: center;">当年占比</div>
      <div style="position: absolute;bottom: 33px;right: 0; width: 70%;text-align: center;">分月趋势</div>
    </div>
    <div class="block">
      <div class="energyWarning">
        <p class="ttitle">用能预警</p>
        <div class="table_wrap">
          <basic-table ref="tableRef" v-bind="tableConfig">
          </basic-table>
        </div>

      </div>
      <div class="deviceWarning">
        <p class="ttitle">设备告警</p>
        <div class="table_wrap">
          <basic-table ref="tableRef2" v-bind="tableConfig2">
          </basic-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// defineOptions({
//   name: 'ProjectBoard'
// })
import { BasicTable } from '@/components/basic/table';
import SvgIcon from "@/components/basic/svgIcon/svg-icon.vue";
import { getCurrentInstance, onMounted, ref, reactive, onBeforeUnmount, markRaw, watch, computed } from "vue";
import { useRoute } from 'vue-router';
import { apiUserProjectTree, apiWeather } from "@/api/common.js";
import { apiBoardProjectInfo, apiBoardElectricity, apiBoardEnergyInfo, apiBoardEnergyDayInfo, apiBoardEnergyWeekInfo, apiBoardEnergyYearCost } from "@/api/centerMonitor/projectBoard.js";
//import { message } from 'ant-design-vue';

const visible = ref(false)
const handleSelectTree = (...arg) => {

  projectIds.value = [arg[0].pop()]
  projectInfo.value = arg[1].checkedNodes.pop()

  initInfoData()
}


const projectInfo = ref({})
const projectIds = ref(null)
const treeData = ref([])
const initProjectTree = async () => {

  let res = await apiUserProjectTree()
  const { children } = res.result || {}
  treeData.value = children || []
  projectInfo.value = findFirstProject(res.result)
  projectIds.value = [projectInfo.value.projectBizId]
  console.log(123, projectInfo.value)
}
const findFirstProject = (source) => {
  let findFlag = false
  let result;
  function findLoop(source) {
    if (findFlag) return;
    console.log(123, source)
    if (source.projectBizId) {
      findFlag = true
      result = source
    }
    if (source.children) {
      source.children.forEach(item => {
        if (item.projectBizId && !findFlag) {
          result = item
          findFlag = true
        } else if (!findFlag) {
          findLoop(item)
        }
      });
    }
  }
  findLoop(source)
  return result
}


const projectData = ref({})
const initProjectInfo = async () => {
  try {
    let result = await apiBoardProjectInfo({ bizProjectId: projectInfo.value.projectBizId })
    projectData.value = result.result
  } catch (error) {
    projectData.value = {}
  }

}

const weatherInfo = ref({})
const initWeather = () => {
  apiWeather(projectInfo.value.projectBizId).then(res => {
    weatherInfo.value = res.result || {}
  })
}

const aqiGradeStyle = computed(() => {

  let color, bgColor
  if (weatherInfo.value.aqiGrade == '优') {
    color = '#04A724'
    bgColor = '#E9FAEC'
  } else if (weatherInfo.value.aqiGrade == '良') {
    color = 'darkolivegreen'
    bgColor = '#E9FAEC'
  } else if (weatherInfo.value.aqiGrade == '一般') {
    color = 'darkorange'
    bgColor = '#E9FAEC'
  } else if (weatherInfo.value.aqiGrade == '差') {
    color = 'red'
    bgColor = '#E9FAEC'
  }
  return {
    background: bgColor,
    color: color
  }
})

const energyData = ref({})
const initEnergyInfo = async () => {
  try {
    let result = await apiBoardEnergyInfo({ bizProjectId: projectInfo.value.projectBizId })
    energyData.value = result.result
  } catch (error) {
    energyData.value = {}
  }

}

const initEnergyDayInfo = async () => {
  let { result } = await apiBoardEnergyDayInfo({ bizProjectId: projectInfo.value.projectBizId })
  console.log(result);
  chartOptions.dayEnergyChartOption.series[0].data = result.today.ylist
  chartOptions.dayEnergyChartOption.series[1].data = result.yestoday.ylist
  dayEnergyChart.value.setOption(chartOptions.dayEnergyChartOption);
}


const energyWeekData = ref({})
const initEnergyWeekInfo = async () => {

  let result = await apiBoardEnergyWeekInfo({ bizProjectId: projectInfo.value.projectBizId })
  energyWeekData.value = result.result
  findTypeWeekEnergyInfoAndSetChartData('电')
}
const findTypeWeekEnergyInfoAndSetChartData = (typeName) => {
  if (Array.isArray(energyWeekData.value)) {
    let info = energyWeekData.value.find(item => item.type === typeName)
    if (info) {
      sevenDayEnergyChart.value.clear()
      if (typeName === '电') {
        chartOptions.sevenDayEnergyChartOption.title.text = '单位：kWh'
        chartOptions.sevenDayEnergyChartOption.series[0].tooltip = {
          valueFormatter: function (value) {
            return value + 'kWh';
          }
        }
        chartOptions.sevenDayEnergyChartOption.series[1].tooltip = {
          valueFormatter: function (value) {
            return value + 'kWh';
          }
        }
      } else {
        chartOptions.sevenDayEnergyChartOption.title.text = '单位：m³'
        chartOptions.sevenDayEnergyChartOption.series[0].tooltip = {
          valueFormatter: function (value) {
            return value + 'm³';
          }
        }
        chartOptions.sevenDayEnergyChartOption.series[1].tooltip = {
          valueFormatter: function (value) {
            return value + 'm³';
          }
        }
      }
      chartOptions.sevenDayEnergyChartOption.series[0].data = info.thisWeek.attrs || []
      chartOptions.sevenDayEnergyChartOption.series[1].data = info.lastWeek.attrs || []
      sevenDayEnergyChart.value.setOption(chartOptions.sevenDayEnergyChartOption);
    }
  }
}
const weekenergyChange = (val) => {
  findTypeWeekEnergyInfoAndSetChartData(val.target.value)
}

const yearCost = ref()
const yearCostYoy = ref()
const initEnergyYearCost = async () => {

  let result = await apiBoardEnergyYearCost({ bizProjectId: projectInfo.value.projectBizId })
  console.log(33, result)
  chartOptions.yearEnergyChartOption.series[0].data = [
    {
      name: '电费',
      value: result.result.energyUsageFee
    },
    {
      name: '水费',
      value: result.result.waterFee
    },
    {
      name: '燃气费',
      value: result.result.gasFee
    },
  ]
  chartOptions.yearEnergyChartOption.series[1].data = [
    {
      name: '尖电费',
      value: result.result.energyUsageFeeTip
    },
    {
      name: '峰电费',
      value: result.result.energyUsageFeePeek
    },
    {
      name: '谷电费',
      value: result.result.energyUsageFeeValley
    },
    {
      name: '平电费',
      value: result.result.energyUsageFeeFlat
    },
    {
      name: '购水费',
      value: result.result.waterFeeWater
    },
    {
      name: '污水费',
      value: result.result.waterFeeSewerage
    },
    {
      name: '燃气费',
      value: result.result.gasFee
    },
  ]
  yearEnergyChart.value.setOption(chartOptions.yearEnergyChartOption);
  yearCost.value = result.result.totalFee
  yearCostYoy.value = result.result.yoy

}

// const energyCarbonInfo = ref({})
// const initEnergyCarbonInfo = async () => {

//   let result = await apiBoardEnergyYearCarbon({ bizProjectId: projectInfo.value.projectBizId })
//   if (result.result) {
//     energyCarbonInfo.value = result.result
//     chartOptions.yearTanChartOption.series[0].data = [
//       {
//         name: '用电',
//         value: result.result.eleCO2Ratio
//       },
//       {
//         name: '用水',
//         value: result.result.waterCO2Ratio
//       },
//       {
//         name: '用气',
//         value: result.result.gasCO2Ratio
//       }
//     ]
//     yearTanChart.value.setOption(chartOptions.yearTanChartOption);
//   }
// }

const boardType = ref('1')
const initElectricity = async () => {
  let result = await apiBoardElectricity({ bizProjectId: projectInfo.value.projectBizId, boardType: boardType.value })
  console.log(23, result)
  if (result.result) {
    const { pieChartData, barChartData } = result.result
    chartOptions.yearElectricityChartOption.series[0].data = pieChartData[0].xlist.map((item, index) => {
      return {
        name: item,
        value: pieChartData[0].ylist[index]
      }
    })
    if (boardType.value == 1) {
      chartOptions.yearElectricityChartOption.title.text = '分类能耗占比'
    } else if (boardType.value == 2) {
      chartOptions.yearElectricityChartOption.title.text = '尖峰谷平占比'
    } else if (boardType.value == 3) {
      chartOptions.yearElectricityChartOption.title.text = '分区能耗占比'
    }
    yearElectricityChart.value.setOption(chartOptions.yearElectricityChartOption);

    yearElectricityChart2.value.clear()
    // chartOptions.yearElectricityChartOption2.legend.data = barChartData.map(item => item.desc)
    chartOptions.yearElectricityChartOption2.xAxis[0].data = barChartData[0].xlist
    chartOptions.yearElectricityChartOption2.series = []
    let bb = barChartData.map((item, index) => {
      return {
        name: item.desc,
        type: 'bar',
        stack: 'Ad',
        barWidth: 20,
        itemStyle: {
          color: ['#3BA272', '#91CC75', '#73C0DE', '#548CFF', '#5470C6', '#9A60B4', '#EA7CCC', '#EE6666', '#FC8452', '#FAC858'][index]
        },
        tooltip: {
          valueFormatter: function (value) {
            return value ? (value + 'kWh') : '-';
          }
        },
        data: item.ylist
      }
    })
    console.log(12121, bb)
    chartOptions.yearElectricityChartOption2.series = bb
    console.log(33333333, chartOptions.yearElectricityChartOption2)
    yearElectricityChart2.value.setOption(chartOptions.yearElectricityChartOption2);


  }
}




const route = useRoute()
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: false,
  isPageBottom: true,
  remote: false, //!!!!!!!!!!!!!!!!!!!!!!!
  columns: [
    {
      title: "时间",
      type: "text",
      key: "id",
    },
    {
      title: "类别",
      type: "text",
      key: "project_name",
    },
    {
      title: "预警内容",
      type: "text",
      key: "project_code",
    }
  ],
})
const tableConfig2 = reactive({
  showIndex: false,
  showSelect: false,
  pagination: false,
  isPageBottom: true,
  remote: false, //!!!!!!!!!!!!!!!!!!!!!!!
  columns: [
    {
      title: "时间",
      type: "text",
      key: "id",
    },
    {
      title: "设备类型",
      type: "text",
      key: "project_name",
    },
    {
      title: "设备名称",
      type: "text",
      key: "project_name",
    },
    {
      title: "预警内容",
      type: "text",
      key: "project_code",
    }
  ],
})

const { proxy } = getCurrentInstance();
const dayEnergyRef = ref()
const dayEnergyChart = ref()

const sevenDayEnergyRef = ref()
const sevenDayEnergyChart = ref()

const yearEnergyChartRef = ref()
const yearEnergyChart = ref()


// const yearTanChartRef = ref()
// const yearTanChart = ref()

const yearElectricityChartRef = ref()
const yearElectricityChart = ref()

const yearElectricityChartRef2 = ref()
const yearElectricityChart2 = ref()


const sevenDayCurrent = ref('电')


const chartOptions = reactive({
  dayEnergyChartOption: {
    title: {
      left: 18,
      top: 60,
      textStyle: {
        fontSize: '10px',
        color: '#8F95B2'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: 10,
      data: ['今日', '昨日'],
      itemWidth: 10,
      itemHeight: 8,
      textStyle: {
        fontSize: 10,
      }
    },
    color: ['#FFC300', '#07B9B9'],
    grid: {
      top: '30%',
      left: '3%',
      right: '4%',
      bottom: '14%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          textStyle: {
            fontSize: 10,
            color: '#8F95B2'
          }
        },
        axisLine: {
          lineStyle: {
            color: '#D8DAE5'
          }
        }
      }
    ],
    yAxis: [
      {
        name: 'kwh',
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        }
      }
    ],
    series: [
      {
        name: '今日',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: []
      },
      {
        name: '昨日',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: []
      }
    ]
  },
  sevenDayEnergyChartOption: {
    title: {
      left: 18,
      top: 60,
      textStyle: {
        fontSize: '10px',
        color: '#8F95B2'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      bottom: 10,
      data: ['本周', '上周'],
      itemWidth: 10,
      itemHeight: 8,
      textStyle: {
        fontSize: 10,
      }
    },
    color: ['#548CFF', '#30BF78'],
    grid: {
      top: '30%',
      left: '3%',
      right: '4%',
      bottom: '14%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          textStyle: {
            fontSize: 10,
            color: '#8F95B2'
          }
        },
        axisLine: {
          lineStyle: {
            color: '#D8DAE5'
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        }
      }
    ],
    series: [
      {
        name: '本周',
        type: 'bar',
        barWidth: 16,
        barGap: 0,
        data: []
      },
      {
        name: '上周',
        type: 'bar',
        barWidth: 16,
        barGap: 0,
        data: []
      }
    ]
  },
  yearEnergyChartOption: {
    // grid: {
    //   top: '30%',
    //   left: '0%',
    //   right: '30%',
    //   bottom: '14%',
    //   // containLabel: true
    // },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c}元 ({d}%)'
    },
    legend: [
      {
        orient: 'horizontal',
        left: '60%',
        top: '80px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['电费']
      },
      {
        orient: 'horizontal',
        left: '65%',
        top: '100px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['尖电费']
      },
      {
        orient: 'horizontal',
        left: '65%',
        top: '120px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['峰电费']
      },
      {
        orient: 'horizontal',
        left: '65%',
        top: '140px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['谷电费']
      },
      {
        orient: 'horizontal',
        left: '65%',
        top: '160px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['平电费']
      },
      {
        orient: 'horizontal',
        left: '60%',
        top: '180px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['水费']
      },
      {
        orient: 'horizontal',
        left: '65%',
        top: '200px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['购水费']
      },
      {
        orient: 'horizontal',
        left: '65%',
        top: '220px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['污水费']
      },
      {
        orient: 'horizontal',
        left: '60%',
        top: '240px',
        itemWidth: 10,
        itemHeight: 8,
        data: ['燃气费']
      }
    ],
    series: [
      {
        name: '当年能耗成本',
        type: 'pie',
        center: ['30%', '55%'],
        radius: [0, '30%'],
        label: {
          show: false
        },
        color: ['#165DFF', '#00B2FF', '#FFC300'],
        labelLine: {
          show: false
        },
        data: []
      },
      {
        name: '当年能耗成本',
        type: 'pie',
        center: ['30%', '55%'],
        radius: ['45%', '60%'],
        labelLine: {
          length: 30
        },
        label: {
          show: false
        },
        color: ['#457DFF', '#407DcF', '#739EFF', '#A2BEFF', '#4DCAFF', '#97E0FF', 'FFC300'],
        data: []
      }
    ]
  },
  yearEnergyChartOption2: {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      top: '25%',
      left: '18%',
      right: '34%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      type: 'category',
      data: ['燃气', '污水', '购水', '水费', '平', '谷', '峰', '电费'],
      axisLine: { // 隐藏Y轴
        show: false
      },
      axisTick: { // 隐藏刻度线
        show: false
      },
    },
    series: [
      {
        name: '2011',
        type: 'bar',
        barWidth: 10,
        data: [60, 30, 30, 60, 30, 30, 30, 60],
        itemStyle: {
          normal: {
            //这里是颜色
            color: function (params) {
              //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
              var colorList = ['#165DFF', '#147AD6', '#EC6666', '#79D2DE', '#00B2FF', '#945FB9', '#1E9493', '#FF9845'];
              return colorList[params.dataIndex]
            }
          }
        }
      }
    ]
  },
  yearTanChartOption: {
    title: {
      text: "水电气",
      subtext: "当年碳排占比",
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#111"
      },
      subtextStyle: {
        fontSize: 14,
        color: "#111"
      },
      left: 'center',
      top: '43%'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ({d}%)'
    },
    legend: {
      bottom: '5%',
      data: [
        '用电',
        '用水',
        '用气',
      ],
      itemWidth: 10,
      itemHeight: 8,
      textStyle: {
        fontSize: 10,
      }
    },
    color: ['#3F86FF', '#00B2FF', '#FFC300'],
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['45%', '60%'],
        label: {
          show: true,
          formatter(param) {
            // correct the percentage
            return param.name + ' (' + param.percent + '%)';
          }
        },
        data: []
      }
    ]
  },
  yearElectricityChartOption: {
    title: {
      text: "",
      textStyle: {
        fontSize: 14,
        fontWeight: "normal",
        color: "#111"
      },
      left: 'center',
      top: '62%'
    },
    tooltip: {
      trigger: 'item',
      formatter: function ({ name, value, percent }) {
        return `<div style="font-size:12px;color:#999">${name}</div><div style="padding-right:10px;font-size:12px;line-height:22px;color:#333;font-weight:bold"><span>${value} kwh</span><span>（${percent}%）</span></div> `
      }
    },
    color: ['#3BA272', '#91CC75', '#73C0DE', '#548CFF', '#5470C6', '#9A60B4', '#EA7CCC', '#EE6666', '#FC8452', '#FAC858'],
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['35%', '60%'],
        center: ['50%', '65%'],
        label: {
          show: true,
          formatter(param) {
            // correct the percentage
            return param.percent + '%';
          }
        },
        data: []
      }
    ]
  },
  yearElectricityChartOption2: {
    title: {
      text: '单位：kWh',
      left: 24,
      top: 80,
      textStyle: {
        fontSize: '10px',
        color: '#8F95B2'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    // legend: {
    //   bottom: '8%',
    //   left: '25%',
    //   data: [],
    //   itemWidth: 10,
    //   itemHeight: 8,
    //   textStyle: {
    //     fontSize: 10,
    //   }
    // },
    color: ['#3BA272', '#91CC75', '#73C0DE', '#548CFF', '#5470C6', '#9A60B4', '#EA7CCC', '#EE6666', '#FC8452', '#FAC858'],
    grid: {
      top: '32%',
      left: '3%',
      right: '4%',
      bottom: '20%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          textStyle: {
            fontSize: 10,
            color: '#8F95B2'
          }
        },
        axisLine: {
          lineStyle: {
            color: '#D8DAE5'
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        }
      }
    ],
    series: []
  },
})

const resizeChart = () => {
  console.log(2222, 'resize')
  dayEnergyChart.value.resize();
  sevenDayEnergyChart.value.resize();
  yearEnergyChart.value.resize();
  // yearTanChart.value.resize();
  yearElectricityChart.value.resize();
  yearElectricityChart2.value.resize();
};

const onResize = () => {
  window.addEventListener("resize", resizeChart);
};

onBeforeUnmount(() => {
  window.removeEventListener("resize", resizeChart);
});

watch(() => route.path, (val) => {
  if (val === '/centerMonitor/projectBoard') {
    setTimeout(() => {
      resizeChart()
    }, 100)
  }
})
const initInfoData = () => {
  initProjectInfo()
  initEnergyInfo()
  initEnergyDayInfo()
  initEnergyWeekInfo()
  initEnergyYearCost()
  // initEnergyCarbonInfo()
  initWeather()
  initElectricity()
}
onMounted(async () => {
  await initProjectTree()
  initInfoData()
  dayEnergyChart.value = markRaw(proxy.$echarts.init(dayEnergyRef.value));
  dayEnergyChart.value.setOption(chartOptions.dayEnergyChartOption);

  sevenDayEnergyChart.value = markRaw(proxy.$echarts.init(sevenDayEnergyRef.value));
  sevenDayEnergyChart.value.setOption(chartOptions.sevenDayEnergyChartOption);

  yearEnergyChart.value = markRaw(proxy.$echarts.init(yearEnergyChartRef.value));
  yearEnergyChart.value.setOption(chartOptions.yearEnergyChartOption);

  // yearTanChart.value = markRaw(proxy.$echarts.init(yearTanChartRef.value));
  // yearTanChart.value.setOption(chartOptions.yearTanChartOption);

  yearElectricityChart.value = markRaw(proxy.$echarts.init(yearElectricityChartRef.value));
  yearElectricityChart.value.setOption(chartOptions.yearElectricityChartOption);

  yearElectricityChart2.value = markRaw(proxy.$echarts.init(yearElectricityChartRef2.value));
  yearElectricityChart2.value.setOption(chartOptions.yearElectricityChartOption2);

  setTimeout(() => {
    onResize()
  }, 100)



})

</script>
<style lang='less' scoped>
strong,
em {
  font-style: normal;
  font-weight: normal;
}

@media (max-width: 1400px) {
  .common-wrapper {
    width: 1200px;
    overflow: auto;
  }
}

.block {
  display: flex;
  margin-bottom: 16px;

  .ttitle {
    position: absolute;
    left: 20px;
    top: 20px;
    line-height: 16px;
    font-size: 16px;
    font-weight: bold;
  }

  &.top {
    .item {
      flex: 1;
      min-width: 210px;
      height: 120px;
      margin-right: 14px;
      border-radius: 4px;
      border: 1px solid #E5E8EF;

      &:last-child {
        margin-right: 0;
      }
    }

    .first {
      padding: 10px 0 0 20px;
      border: none;
      background: linear-gradient(180deg, #E5ECFF 0%, #F8FAFF 100%);

      .title {
        line-height: 22px;
        font-size: 14px;
        margin-bottom: 11px;
        display: flex;
        align-items: center;
      }

      &>div {
        display: flex;

        section {
          &:first-child {
            width: 65%;
          }

          &:last-child {
            width: 35%;
          }
        }

        span {
          display: inline-block;
          margin-bottom: 4px;
          font-size: 12px;
          line-height: 20px;
          color: #333;
        }

        p {
          font-size: 24px;
          line-height: 22px;
          color: #1D2129;
          font-weight: 500;

          em {
            font-size: 12px;
            color: #666;
          }
        }
      }
    }

  }

  .normal {
    position: relative;
    padding: 8px 0 0 32px;

    .icon-tip {
      position: absolute;
      left: 8px;
      top: 12px;
    }

    strong {
      font-size: 12px;
    }

    span {
      display: inline-block;
      line-height: 12px;
      font-size: 12px;
      color: #333;
    }

    p {
      width: 60%;
      font-size: 20px;
      line-height: 22px;
      color: #1D2129;
      margin-top: 2px;
      font-weight: 500;

      em {
        font-size: 12px;
        color: #666;
      }
    }

    section {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 4px;
      }
    }
  }

  .sevenDay,
  .weather,
  .yearAnalysis,
  .energyWarning,
  .deviceWarning,
  .yearData {
    position: relative;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #E6E8EB;
  }

  .sevenDay,
  .energyWarning,

  .yearAnalysis {
    box-sizing: border-box;
    padding: 0 20px;
    width: 52%;
    height: 300px;
    margin-right: 14px;


    .yearCost {
      padding: 0 20px;
      width: 30%;
      height: 122px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #E5E8EF;
      font-size: 14px;
      line-height: 40px;
      color: #333333;
    }
  }

  .weather {
    &>div {
      margin-top: 78px;
      display: flex;
      width: 100%;
    }

    &>p {
      span {
        margin-left: 20px;
        font-size: 12px;
      }
    }

    .left {
      width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        margin-right: 20px;
      }

      div {
        div {
          display: flex;
          font-size: 36px;
          color: #222;

          em {
            font-size: 12px;
            margin-top: 10px;
          }
        }

        strong {
          font-size: 18px;
          color: #333;
        }
      }
    }

    .right {
      flex: 1;

      div {
        display: flex;
        width: 80%;
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #E6E8EB;
        justify-content: space-between;
        font-size: 14px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .yearAnalysis,
  .yearData {
    display: flex;

    .other_info {
      margin-top: 76px;
      margin-left: 20px;
      flex: 1;

      div {
        display: flex;
      }

      section {
        width: 100px;
        height: 74px;
        padding: 12px;
        border-radius: 4px;
        margin-right: 12px;
        margin-bottom: 12px;
        background: #F8F8F8;

        p {
          font-size: 26px;
          font-weight: 500;
          line-height: 34px;

          em {
            margin-left: 5px;
            font-size: 12px;
            color: #999;
          }
        }
      }
    }
  }

  .sevenDay {
    position: relative;

    .title_action {
      width: calc(100% - 40px);
      display: flex;
      position: absolute;
      top: 16px;
      left: 20px;
      right: 20px;
      justify-content: space-between;
      z-index: 2;

      p {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
      }
    }
  }

  .weather,
  .deviceWarning,
  .yearData {
    flex: 1;
    min-width: 571px;
    height: 300px;
  }

  &.yearElectricity {
    position: relative;
    width: 100%;
    height: 370px;
    border-radius: 4px;
    border: 1px solid #E6E8EB;

    .title_action {
      width: calc(100% - 40px);
      display: flex;
      position: absolute;
      top: 16px;
      left: 20px;
      right: 20px;
      justify-content: space-between;
      z-index: 2;

      p {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
      }
    }
  }

}

.table_wrap {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 56px;
}

.treeStyle {
  position: relative;
  padding: 18px 12px;
  box-shadow: 0 0 2px 2px #D8D8D8;
  background: #fff;
}

.pm25Grade {
  display: flex;
  align-items: center;

  span {
    width: 20px;
    height: 18px;
    line-height: 18px;
    margin-right: 10px;
    text-align: center;
    border-radius: 2px;
    font-size: 12px;
  }
}

.yearAnalysisLegend {
  margin-top: 50px;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mar {
    margin-left: 28px;
  }

  span {
    display: block;
    margin-bottom: 4px;

    em {
      display: inline-block;
      margin-left: 3px;
      width: 20px;
      height: 10px;
      border-radius: 2px;
    }
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #8F95B2;
  border-color: #8F95B2;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #8F95B2;
}
</style>