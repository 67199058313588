import http from '@/utils/http'


/*
 * 锦江定制看板-----项目数据
 * @param {*} params 
 * @returns 
 */
export function apiBoardProjectInfo(params){
    return http.request({
        url: '/cemp-energy/sta/board/RJD/projectInfo',
        method: 'get',
        params
    })
}

/*
 * 锦江定制看板-----能耗数据
 * @param {*} params 
 * @returns 
 */
export function apiBoardEnergyInfo(params){
    return http.request({
        url: '/cemp-energy/sta/board/RJD/enegy',
        method: 'get',
        params
    })
}

/*
 * 锦江定制看板-----日能耗数据
 * @param {*} params 
 * @returns 
 */
export function apiBoardEnergyDayInfo(params){
    return http.request({
        url: '/cemp-energy/sta/board/RJD/energy/day',
        method: 'get',
        params
    })
}

/*
 * 锦江定制看板-----7日能耗数据
 * @param {*} params 
 * @returns 
 */
export function apiBoardEnergyWeekInfo(params){
    return http.request({
        url: '/cemp-energy/sta/board/RJD/energy/week',
        method: 'get',
        params
    })
}

/*
 * 锦江定制看板-----当年能耗成本
 * @param {*} params 
 * @returns 
 */
export function apiBoardEnergyYearCost(params){
    return http.request({
        url: '/cemp-energy/sta/board/RJD/energy/cost',
        method: 'get',
        params
    })
}

/*
 * 锦江定制看板-----当年碳排
 * @param {*} params 
 * @returns 
 */
export function apiBoardEnergyYearCarbon(params){
    return http.request({
        url: '/cemp-energy/sta/board/RJD/energy/carbon',
        method: 'get',
        params
    })
}

/*
 * 锦江定制看板-----电耗分析
 * @param {*} params 
 * @returns 
 */
export function apiBoardElectricity(params){
    return http.request({
        url: '/cemp-energy/sta/board/RJD/electricity/year',
        method: 'get',
        params
    })
}